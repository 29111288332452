import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  main: {
    title: 'Апталық тест',
    desc: 'Апта сайын тест тапсырып, нәтижеңізді біздің рейтингте көріңіз',
    test_still_available: 'Тестті әлі өтуге болады:',
    test_ends_in: 'Тесттің аяқталу уақыты:',
    rating_shows_in: 'Тест нәтижелері дайын болады:',
    next_test_starts_in: 'Келесі тесттің басталу уақыты:',
    my_results: 'Менің нәтижелерім',
    week_results: 'Апта нәтижелері',
    not_passed: 'Сіз апталық тестті тапсырған жоқсыз',
    pass_next_time: 'Келесі жолы тестті тапсырып, нәтижеңізді біздің рейтингте алыңыз',
    total_rating: 'Жалпы рейтинг',
    my_friends: 'Менің достарым',
    rating: {
      empty: 'Рейтинг тізімі бос',
      placeholder: 'Апта сайынғы тестілеуден өтіңіз, сонда сіз апталық тест нәтижелері бойынша оқушылардың рейтингін көре аласыз',
      contacts_hint: 'Сыныбыңызда тағы кім тест тапсырғанын білгіңіз келе ме?',
      contacts_not_implemented_hint: 'Сыныбыңызда тағы кім тест тапсырғанын білгіңіз келе ме? Мұны істеу үшін қосымшаны жаңартыңыз.',
      update_app: 'Қосымшаны жаңарту',
      share_contacts: 'Контактілермен бөлісу',
      update_contacts: 'Контактілерді жаңарту',
      no_friends_passed_yet: 'Достарыңыздың ешқайсысы әлі тестті тапсырған жоқ',
      filter: {
        first_subject: 'Бірінші пән',
        second_subject: 'Екінші пән',
        select_subjects: 'Пәндерді таңдаңыз',
        all_subjects: 'Барлық пәндер',
      },
      place: 'орын',
      points: '{{points}} ұпай',
      results_title: 'Апта нәтижелері',
      results_desc: 'Апталық тест жабылды! Жеңімпаздарды құттықтаймыз. Келесі сынақ дүйсенбіде ашылады.',
    },
    history_plug_modal: {
      title: 'Менің тесттерім',
      message: 'Жақында «Менің тесттерім» бөлімі қолжетімді болады, онда сіз барлық аяқталған апталық тесттеріңізді көре аласыз және қатемен жұмыс жүргізе аласыз.',
    },
    contacts_permission_denied: {
      title: 'Контактілер',
      message: 'Контактілер тізімін алуға тыйым салынды. Телефон параметрлеріне өтіп, Joo.kz қосымшасына контактілер тізімін алуға рұқсат беріңіз.',
    },
    cannot_fetch_contacts: {
      title: 'Контактілер',
      message: 'Телефоннан контактілер тізімін алу кезінде қате орын алды. Өтінеміз, кейінрек қайталаңыз.',
    },
    cannot_update_contacts: {
      title: 'Контактілер',
      message: 'Контактілер тізімін сақтау кезінде қате орын алды. Өтінеміз, кейінрек қайталаңыз.',
    },
    empty_contacts: {
      title: 'Назар аударыңыз',
      message: 'Контактілер тізімі бос.',
    },
    saved_contacts: {
      title: 'Менің достарым',
      message: 'Достар рейтингі сәтті жаңартылды.',
    },
  },
  configure: {
    select_language: 'Тілді таңдаңыз',
    select_subjects: 'Пәндерді таңдаңыз',
    select_first_subject: 'Бірінші пәнді таңдаңыз',
    select_second_subject: 'Екінші пәнді таңдаңыз',
    required_subject: 'Міндетті пән',
    start_test: 'Тестті бастау',
    cannot_select_subject_modal: {
      title: 'Назар аударыңыз',
      message: 'Алдымен бірінші пәнді таңдаңыз.',
    },
  },
  subjects: {
    waiting_test_generation: 'Күте тұрыңыз.<br>Сіздің тест дайындалуда.',
    finish_test_modal: {
      title: 'Тестті аяқтау',
      message: 'Тестті аяқтағыңыз келетініне сенімдісіз бе? Тестті аяқтаған соң жауаптарыңызды өзгерте алмайсыз.',
      cancel_text: 'Болдырмау',
      confirm_text: 'Тестті аяқтау',
    },
  },
  results: {
    title: 'Менің нәтижелерім',
    place: 'жалпы рейтингте орныңыз',
    total: 'жалпы ұпайыңыз',
    points: '{{count}} ұпай',
    worst: 'Сізге дайындалу керек тақырыптар:',
    best: 'Сіз жақсы білетін тақырыптар:',
  },
  history: {
    title: 'Менің тесттерім',
    empty: 'Сіз тапсырған апталық тестілер тізімі осы жерде көрсетіледі',
    points: '{{points}} ұпай',
    graph: 'Соңғы сынақ кестесі',
  },
}

export default locale
