import {LocaleDataModel} from '@ui/schemes/app/locale.model'
import lesson_card from './lesson-card/kk'
import homework_card from './homework-card/kk'
import test from './test/kk'

const locale: LocaleDataModel = {
  // shared components
  lesson_card,
  homework_card,
  test,
  // often used
  all: 'Барлығы',
  lessons: 'Сабақтар',
  lectures: 'Лекциялар',
  homeworks: 'Үй тапсырмалары',
  lesson_count: 'Сабақтар: {{count}}',
  lesson_order: 'Сабақ №{{order}}',
  look: 'Көру',
  apply: 'Көру',
  reset: 'Тазарту',
  save: 'Сақтау',
  yesterday: 'Кеше',
  today: 'Бүгін',
  tomorrow: 'Ертең',
  days: '{{d}} күн',
  hours: '{{h}} сағ',
  minutes: '{{m}} мин',
  seconds: '{{s}} сек',
  start: 'Бастау',
  continue: 'Жалғастыру',
  finish: 'Аяқтау',
  finished: 'Аяқталды',
  loading: 'Күте тұрыңыз...',
  empty_list: 'Тізім бос',
  edu_type_change_btn: 'Дайындық режимін өзгерту',
  edu_type_picker: {
    title: 'Дайындық режимін таңдаңыз',
    message: 'Жалғастыру үшін дайындық режимін таңдаңыз. Дайындық режимін кейінірек кез келген уақытта өзгерте аласыз.',
    types: [
      'Жоспарланған',
      'Тәуелсіз',
    ],
  },
  you: 'Сіз',
  find: 'Іздеу',
  warning: 'Назар аударыңыз',
  server_error: 'Сервер қатесі',
  not_found: 'Табылмады',
  server_error_try_later: 'Сервер қатесі. Сәл кейінірек қайталап көріңіз.',
  cancel: 'Болдырмау',
  navigate: 'Өту',
}

export default locale
