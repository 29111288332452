import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  are_you_ready: 'Тестке дайынсыз ба?',
  type: {
    1: {
      title: 'Аралық тест',
      message: 'Аралық бақылау тест - өтілген тақырыптарды қаншалықты меңгергеніңізді анықтауға көмектеседі. Сұрақтар өтілген соңғы {{questions}} тақырып бойынша келеді. Бұл тестті тапсыру арқылы қай тақырыпты түсінгеніңізді немесе қай тақырыпты қайталау керектігін анықтайтын боласыз. Сәттілік!',
    },
    2: {
      title: 'Қорытынды тест',
      message: 'Қорытынды бақылау тест - бұл бүкіл модульді қаншалықты жақсы меңгергеніңізді анықтауға көмектеседі. Тест сұрақтары модульдің барлық тақырыптарын қамтиды. Осы сынақтан өткеннен кейін сіз прогрессіңізді анықтап, қандай тақырыптарды қайталауыңыз керек екенін түсінесіз. Сәттілік!',
    },
  },
  your_result: 'Сіздің нәтижеңіз',
  result: {
    low: 'Сіз төмен нәтиже көрсеттіңіз. Яғни кейбір тақырыптарды қайталауыңыз қажет.',
    middle: 'Сіз орташа нәтиже көрсеттіңіз. Яғни кейбір тақырыптарды қайталауыңыз қажет.',
    high: 'Керемет! Сіз жоғары нәтиже көрсеттіңіз.',
  },
}

export default locale
