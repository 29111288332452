import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Обучение',
  lessons_empty: 'Сегодня нет уроков',
  homeworks_empty: 'Нет домашних заданий',
  sections: {
    title: 'Разделы обучения',
    list: [
      {
        title: 'Мои уроки',
        description: 'Материалы онлайн-курса',
        expire: 'Есть неоплаченный предмет',
      },
      {
        title: 'Тренажер',
        description: 'Тренируйся выполняя тесты',
      },
      {
        title: 'Вопросы ЕНТ 2022',
        description: 'Отвечай на вопросы ЕНТ',
      },
      {
        title: 'Еженедельный тест',
        description: 'Состязайся с другими студентами',
      },
    ],
  },
  trial_test: {
    title: 'Пробный тест онлайн',
    start: 'Начало',
    end: 'Завершение',
    take_test: 'Пройти тест',
    my_result: 'Мой результат',
  },
  add: 'Добавить',
  your_result: 'Ваш результат',
  trainer: 'Ответьте верно на {{threshold}} вопросов и получите звезду',
  connect_friend: 'Подключить друга',
  waiting_answer: 'Ожидаем ответ',
  accept_request: 'Принять',
  friend_req: {
    search: {
      title: 'Поиск друга',
      subtitle: 'Добавьте друзей и следите за их прогрессом и активностью, мотивируя друг друга',
      label: 'Введите номер телефона',
    },
    found: {
      send_request: 'Отправить запрос',
    },
    sent: {
      title: 'Ваш запрос отправлен',
      message: 'Ваш запрос пользователю <span class="text-primary">{{name}}</span> был отправлен. Ожидайте ответа.',
    },
    accepted: {
      title: 'Ваш запрос принят',
      message: 'Пользователь <span class="text-primary">{{name}}</span> принял ваш запрос. Удачи в обучении!',
    },
    declined: {
      title: 'Ваш запрос отклонен',
      message: 'Пользователь <span class="text-primary">{{name}}</span> отклонил ваш запрос.',
    },
    disconnect: {
      title: 'Подтвердите действие',
      message: 'Вы точно хотите отключиться от пользователя <span class="text-primary">{{name}}</span>?',
      confirm: 'Да, отключиться',
    },
  },
  lessons: 'Уроки',
}

export default locale
