import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Система скидок',
  finished: 'Завершен',
  start_at: 'Дата начала:',
  end_at: 'Дата завершения:',
  gained: 'Накоплено:',
  stars: '{{count}} из {{total}} звезд',
  discount_range: '{{goal}} звезд {{percent}}%',
  price: 'Цена: {{price}}₸',
  discount: 'Скидка {{discount}}%',
  pay_btn: 'К оплате {{price}}₸',
  pay_enabled_at: 'Оплата будет доступна {{date}}',
  discount_end_at: 'Скидка доступна до {{date}}',
}

export default locale
