import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  starts_at: 'Басталуы {{date}}',
  started_at: 'Басталды {{date}}',
  finished: 'Аяқталды',
  not_started_modal: {
    title: 'Сабақ әлі басталмады',
    message: 'Сабақтың басталуы: {{date}}',
  },
  order: 'Сабақ №{{order}}',
  test: {
    name: {
      1: 'Аралық тест',
      2: 'Қорытынды тест',
    },
    questions: 'Сұрақ саны: {{count}}',
  }
}

export default locale
