import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable, shareReplay} from 'rxjs'

import {env} from '@ui/env'

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  profile$: Observable<any> = this.http
    .get<any>(`${env.apiHost}/api/v1/profile/`)
    .pipe(shareReplay(1))

  constructor(
    protected http: HttpClient,
  ) {
  }
}
