import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  comments: 'Пікірлер',
  send_comment: 'Жіберу',
  write_comment: 'Пікір жазыңыз',
  plug: 'Сабақ басталып кетті! Кураторыңыздың ортақ чатқа жіберген сілтеме арқылы сабаққа өтіңіз!',
  navigate: 'Трансляцияға өту',
}

export default locale
