import {Injectable, NgZone} from '@angular/core'
import {Router} from '@angular/router'
import {Subject} from 'rxjs'
import {rnWebviewPostMessage} from '@ui/utils/rn-webview'
import {MOBILE_ROUTE_PARAMS, MOBILE_ROUTE_PARAMS_OBSERVABLE} from '@ui/constants/constants'

@Injectable({
  providedIn: 'root',
})
export class RnRouteParamsService {

  constructor(
    private zone: NgZone,
    private router: Router,
  ) {
  }

  init(): void {
    this.handleInitialParamsIfExists()
    this.listenFutureParams()
  }

  handleInitialParamsIfExists(): void {
    this.handleParams(window[MOBILE_ROUTE_PARAMS])
  }

  listenFutureParams(): void {
    window[MOBILE_ROUTE_PARAMS_OBSERVABLE] = new Subject<any>()
    window[MOBILE_ROUTE_PARAMS_OBSERVABLE].asObservable().subscribe({
      next: params => this.handleParams(params),
    })
  }

  handleParams(payload: any): void {
    if (!payload) {
      return
    }
    try {
      if (payload.linkingParams) {
        let match
        const url = payload.linkingParams

        match = url.match(/weekly-test(\/?)$/)

        if (match) {
          this.zone.run(() => this.router.navigate(['/weekly-test']))
        }
      } else if (payload.oneSignalParams) {
        // TODO: handle OneSignal params
      }
    } catch (e) {
      rnWebviewPostMessage({
        event: 'log',
        data: `Error while processing OneSignal params: ${JSON.stringify(e)}`,
      })
    }
  }
}
