import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  students_info: 'Вместе с нами…',
  students_info_short: 'и еще 100+',
  lessons_count: 'Количество уроков: {{count}}',
  themes_count: 'Все темы (еще {{count}})',
  discount: {
    title: 'Как можно сэкономить?',
    message: 'Выполняя каждый день задачи вы получаете звездочки. Собирая определенные количества звезд вы получаете скидки на следующие месяца.',
  },
  pay_btn: 'Оплатить за месяц и начать обучение',
  features: [
    {
      t: 'Теория',
      d: 'Состоит из видеолекции и конспектов по всем темам предмета',
    },
    {
      t: 'Практика',
      d: 'Открываются домашние задания для закрепление темы. Это позволяет применить на практике полученные знания',
    },
    {
      t: 'Тренажер',
      d: 'Состоит из пробных тестов нового формата, основанных на интервальном повторении',
    },
    {
      t: 'Скидки',
      d: 'Каждый день за выполнение задании присваиваются звездочки. За три задания в день даются три звездочки. Вы можете получить скидки на следующие модули, собрав нужное количество звезд',
    },
  ],
}

export default locale
