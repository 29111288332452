import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  not_available_error: 'Үй тапсырмасы қолжетімді емес',
  finish_modal: {
    title: 'Құттықтаймыз!',
    message: 'Сіз бұл сабақ бойынша үй жұмысын аяқтадыңыз. Сіздің нәтижеңіз: {{count}}/{{total}}.',
  },
  star: {
    tutorial: {
      title: 'Іске сәт!',
      message: 'Сұрақтардың 70% дұрыс жауап беріп, жұлдызша алыңыз!',
    },
    leave_warning: {
      title: 'Аяқталмаған',
      message: 'Қалған сұрақтарға жауап беріп, жұлдызша алыңыз',
      confirm: 'Тесттен шығу',
      cancel: 'Тестке оралу',
    },
    gain_success: {
      title: 'Құттықтаймыз!',
      message: 'Дұрыс жауаптар: {{count}}/{{total}} ({{rounded}}%)<br>Сіз 1 жұлдызша алдыңыз',
    },
    gain_fail: {
      title: 'Өкінішке қарай...',
      message: 'Дұрыс жауаптар: {{count}}/{{total}} ({{rounded}}%)<br>Сіз сұрақтардың 70% дұрыс жауап бере алмадыңыз',
    },
  },
}

export default locale
