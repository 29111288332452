import {LocaleModel} from '@ui/schemes/app/locale.model'
import common from './common/ru'
import main from './main/ru'
import trainer from './trainer/ru'
import broadcast from './broadcast/ru'
import subjects from './subjects/ru'
import lesson from './lesson/ru'
import homework from './homework/ru'
import weekly_test from './weekly-test/ru'
import trial_test from './trial-test/ru'
import indep_subjects from './indep-subjects/ru'
import indep_subject_discounts from './indep-subject-discounts/ru'
import indep_buy_course from './indep-buy-course/ru'
import indep_lessons from './indep-lessons/ru'
import indep_lesson from './indep-lesson/ru'
import indep_stream_test from './indep-stream-test/ru'

const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    kk: 'Казахский',
    ru: 'Русский',
    common,
    main,
    trainer,
    broadcast,
    subjects,
    lesson,
    homework,
    weekly_test,
    trial_test,
    indep_subjects,
    indep_subject_discounts,
    indep_buy_course,
    indep_lessons,
    indep_lesson,
    indep_stream_test,
  },
}

export default localeRu
