import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Онлайн тест',
  test_page: 'Тест беті',
  start_test: 'Тестті бастау',
  finish_test: 'Тестті аяқтау',
  start_modal: {
    title: 'Тестті басталу',
    subtitle: 'Тестті бастамас бұрын шарттарды оқып шығыңыз',
    rules: [
      '* Тест <b>240 минутқа</b> созылады',
      '* Тест басталғаннан кейін оны тоқтатуға <b>мүмкіндік болмайды</b>',
      '* Сізге тек <b>1 мүмкіндік</b> беріледі',
      '* Нәтижеңізді барлық пәндер бойынша <b>тесттерді аяқтағаннан кейін</b> ғана көре аласыз',
    ],
    btn: 'Тестті бастау',
  },
  finish_modal: {
    title: 'Тестті аяқтау',
    message: 'Тестті аяқтағыңыз келеді ме? Тестті аяқтаған соң жауаптарыңызды өзгерте алмайсыз.',
    cancel_text: 'Болдырмау',
    confirm_text: 'Тестті аяқтау',
  },
  my_results: 'Менің нәтижелерім',
  overall_points: 'жалпы ұпай саны',
  subject_results: 'Пән бойынша нәтиже',
  test_available_till: 'Тестті <b>{{date}}</b> дейін тапсыруға болады',
  subjects: 'Пәндер',
  select_subject: 'Пән таңдау',
  test_ends_in: 'Тест аяқталады',
}

export default locale
