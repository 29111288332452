import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  use_solution: 'Использовать подсказку ({{left}})',
  solution_order: 'Подсказка #{{order}}',
  check_answer: 'Проверить ответ',
  next_question: 'Следующий вопрос',
  quit: 'Выйти',
  prev: 'Назад',
  next: 'Вперед',
  finish: 'Завершить тест',
  pagination: '{{current}} из {{total}}',
  review_errors: 'Работа над ошибками',
  not_answered: 'Не был отвечен',
  change_answer: 'Изменить ответ',
  select_answer: 'Выберите ответ',
  save_answer: 'Сохранить ответ',
  support: {
    btn: 'Нашли ошибку?',
    title: 'Выберите тип ошибки',
    description_placeholder: 'Если вашей ошибки нет в списке, напишите подробнее здесь...',
    cancel: 'Отмена',
    submit: 'Отправить',
    success_modal: {
      title: 'Спасибо за помощь!',
      subtitle: 'Обнаружив наши ошибки, вы очень поможете нам в нашем развитии!',
      close: 'Закрыть',
    },
  },
}

export default locale
