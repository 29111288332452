import {LocaleModel} from '@ui/schemes/app/locale.model'
import common from './common/kk'
import main from './main/kk'
import trainer from './trainer/kk'
import broadcast from './broadcast/kk'
import subjects from './subjects/kk'
import lesson from './lesson/kk'
import homework from './homework/kk'
import weekly_test from './weekly-test/kk'
import trial_test from './trial-test/kk'
import indep_subjects from './indep-subjects/kk'
import indep_subject_discounts from './indep-subject-discounts/kk'
import indep_buy_course from './indep-buy-course/kk'
import indep_lessons from './indep-lessons/kk'
import indep_lesson from './indep-lesson/kk'
import indep_stream_test from './indep-stream-test/kk'

const localeKk: LocaleModel = {
  lang: 'kk',
  data: {
    kk: 'Қазақша',
    ru: 'Орысша',
    common,
    main,
    trainer,
    broadcast,
    subjects,
    lesson,
    homework,
    weekly_test,
    trial_test,
    indep_subjects,
    indep_subject_discounts,
    indep_buy_course,
    indep_lessons,
    indep_lesson,
    indep_stream_test,
  },
}

export default localeKk
