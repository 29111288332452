import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Пробный тест',
  test_page: 'Страница теста',
  start_test: 'Начать тест',
  finish_test: 'Завершить тест',
  start_modal: {
    title: 'Начало пробного теста',
    subtitle: 'Ознакомьтесь с правилами и условиями перед началом теста',
    rules: [
      '* Тест длится <b>240 минут</b>',
      '* У вас <b>не будет возможности</b> поставить тест на паузу после его начала',
      '* Вам дается только <b>1 попытка</b>',
      '* Результаты будут доступны только <b>после сдачи тестов</b> по всем предметам ',
    ],
    btn: 'Начать пробный тест',
  },
  finish_modal: {
    title: 'Завершить тест',
    message: 'Вы уверены, что хотите завершить тест? После завершения теста вы не сможете изменить свои ответы.',
    cancel_text: 'Отмена',
    confirm_text: 'Завершить тест',
  },
  my_results: 'Мои результаты',
  overall_points: 'всего баллов',
  subject_results: 'Результат по предметам',
  test_available_till: 'Тест доступен до – <b>{{date}}</b>',
  subjects: 'Предметы',
  select_subject: 'Выбор предмета',
  test_ends_in: 'Тест завершится через',
}

export default locale
