import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  description: 'Сипаттама',
  materials: 'Материалдар',
  read_all: 'Бәрін оқу',
  broadcast_tab: 'Тікелей эфир',
  extras_tab: 'Қос. материалдар',
  broadcast_record_unavailable: 'Тікелей эфирдің видеосы әлі дайын емес',
  lock: {
    header: 'Пән төленбеген',
    content: `Келесі айдың оқу ақысы <span class="nowrap color-red">- төленбеген.</span> Толық ақпарат алу үшін <b class="nowrap">+7 747 650 37 22</b> нөміріне хабарласыңыз.`,
  },
  star: {
    tutorial: {
      title: 'Жұлдызшаға ие болу үшін видеосабақты соңына дейін көру қажет',
      message: 'Видеосабақ пен конспекттерді оқып шығыңыз, ол сізге үй тапсырмасын орындауда қажет болады!',
    },
    gain_success: {
      title: 'Тапсырма орындалды!',
      message: 'Құттықтаймыз, сіз лекцияны көргеніңіз үшін 1 жұлдызша алдыңыз.',
    },
  },
}

export default locale
