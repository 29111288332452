import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  not_available_error: 'Домашняя работа недоступна',
  finish_modal: {
    title: 'Поздравляем!',
    message: 'Вы завершили домашнюю работу по данному уроку. Ваш результат: {{count}}/{{total}}.',
  },
  star: {
    tutorial: {
      title: 'Удачи!',
      message: 'Ответьте на 70% вопросов правильно и получите сегодняшнюю звездочку!',
    },
    leave_warning: {
      title: 'Не завершено',
      message: 'Ответьте на оставшиеся вопросы и получите звездочку',
      confirm: 'Выйти с теста',
      cancel: 'Вернуться к тесту',
    },
    gain_success: {
      title: 'Поздравляем!',
      message: 'Правильные ответы {{count}} из {{total}} ({{rounded}}%)<br>Вы получили 1 звезду',
    },
    gain_fail: {
      title: 'Увы...',
      message: 'Правильные ответы {{count}} из {{total}} ({{rounded}}%)<br>К сожалению вы не смогли ответить правильно на 70% вопросов',
    },
  },
}

export default locale
