import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  students_info: 'Бізбен бірге дайындалып жатқандар…',
  students_info_short: 'тағы 100+',
  lessons_count: 'Сабақтар саны: {{count}}',
  themes_count: 'Барлық тақырыпты көру (тағы {{count}})',
  discount: {
    title: 'Қалай үнемдеуге болады?',
    message: 'Күн сайын әр орындалған тапсырмаға жұлдызша беріледі. Жұлдызшаларды жинау арқылы келесі айдың төлеміне жеңілдік ала аласыз.',
  },
  pay_btn: 'Бір айға төлеп, оқуды бастау',
  features: [
    {
      t: 'Теория',
      d: 'Әр пәннің барлық тақырыптары бойынша видеосабақтарды қамтиды',
    },
    {
      t: 'Практика',
      d: 'Тақырыпты бекітуге арналған үй тапсырмалары ашылады. Ол оқығаныңды іс жүзінде қолдануға мүмкіндік береді',
    },
    {
      t: 'Тренажерка',
      d: 'Өткенді ұмытып қалмас үшін интервалды қайталауға негізделген ЖАҢА форматтағы байқау тесттерінен тұрады',
    },
    {
      t: 'Жеңілдіктер',
      d: 'Күн сайын әр орындалған тапсырмаға жұлдызшалар беріледі. Күніне 3 тапсырма = 3 жұлдызша. Жұлдызшалар жинап келесі модульдерге жеңілдіктер алуға болады',
    },
  ],
}

export default locale
