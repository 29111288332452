import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  main: {
    title: 'Еженедельный тест',
    desc: 'Проходите тест каждую неделю и получите свой результат в нашем рейтинге',
    test_still_available: 'Тест доступен еще:',
    test_ends_in: 'Тест завершится через:',
    rating_shows_in: 'Итоги теста через:',
    next_test_starts_in: 'Время начала следующего теста:',
    my_results: 'Мои результаты',
    week_results: 'Итоги недели',
    not_passed: 'Вы не сдавали еженедельный тест',
    pass_next_time: 'Сдайте тест в следующий раз и получите свой результат в нашем рейтинге',
    total_rating: 'Общий рейтинг',
    my_friends: 'Мои друзья',
    rating: {
      empty: 'Рейтинг пуст',
      placeholder: 'Сдавайте еженедельный тест, чтобы вы могли видеть рейтинг учеников на основе результатов еженедельного теста',
      contacts_hint: 'Хотите узнать, кто еще сдавал тест в вашем классе?',
      contacts_not_implemented_hint: 'Хотите узнать, кто еще сдавал тест в вашем классе? Для этого обновите приложение.',
      update_app: 'Обновить приложение',
      share_contacts: 'Поделиться контактами',
      update_contacts: 'Обновить контакты',
      no_friends_passed_yet: 'Никто из ваших друзей еще не прошел тест',
      filter: {
        first_subject: 'Первый предмет',
        second_subject: 'Второй предмет',
        select_subjects: 'Выберите предметы',
        all_subjects: 'Все предметы',
      },
      place: 'место',
      points: '{{points}} балл(-ов)',
      results_title: 'Итоги недели',
      results_desc: 'Еженедельный тест закрыт! Поздравляем победителей. Следующий тест будет доступен в понедельник.',
    },
    history_plug_modal: {
      title: 'Мои тесты',
      message: 'Скоро будет доступен раздел «Мои тесты», где вы сможете просмотреть все свои выполненные еженедельные тесты и провести работу над ошибками.',
    },
    contacts_permission_denied: {
      title: 'Контакты',
      message: 'Доступ к контактам запрещен. Зайдите в настройки телефона и разрешите доступ к контактам приложению Joo.kz.',
    },
    cannot_fetch_contacts: {
      title: 'Контакты',
      message: 'Произошла ошибка при получении списка контактов с телефона. Пожалуйста, попробуйте позже.',
    },
    cannot_update_contacts: {
      title: 'Контакты',
      message: 'Произошла ошибка при сохранении списка контактов. Пожалуйста, попробуйте позже.',
    },
    empty_contacts: {
      title: 'Внимание',
      message: 'Список контактов пуст.',
    },
    saved_contacts: {
      title: 'Мои друзья',
      message: 'Рейтинг друзей успешно обновлен.',
    },
  },
  configure: {
    select_language: 'Выберите язык',
    select_subjects: 'Выберите предметы',
    select_first_subject: 'Выбрать первый предмет',
    select_second_subject: 'Выбрать второй предмет',
    required_subject: 'Обязательный предмет',
    start_test: 'Начать тест',
    cannot_select_subject_modal: {
      title: 'Внимание',
      message: 'Сначала выберите первый предмет.',
    },
  },
  subjects: {
    waiting_test_generation: 'Подождите.<br>Ваш тест генерируется.',
    finish_test_modal: {
      title: 'Завершить тест',
      message: 'Вы уверены, что хотите завершить тест? После завершения теста вы не сможете изменить свои ответы.',
      cancel_text: 'Отмена',
      confirm_text: 'Завершить тест',
    },
  },
  results: {
    title: 'Мои результаты',
    place: 'место в общем рейтинге',
    total: 'всего баллов',
    points: '{{count}} балл(-ов)',
    worst: 'Темы, которые нужно изучить:',
    best: 'Темы, которые вы хорошо знаете:',
  },
  history: {
    title: 'Мои тесты',
    empty: 'Здесь отображается список пройденных вами еженедельных тестов',
    points: '{{points}} балл',
    graph: 'График последних тестов',
  },
}

export default locale
