import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  are_you_ready: 'Вы готовы к тесту?',
  type: {
    1: {
      title: 'Промежуточный тест',
      message: 'Промежуточный тест – помогает определить, насколько хорошо вы усвоили пройденные темы. Вопросы будут из последних пройденных {{questions}} тем. Пройдя этот тест, вы узнаете, какую тему вы усвоили, а какую вам нужно повторить. Удачи!'
    },
    2: {
      title: 'Контрольный тест',
      message: 'Контрольный тест – он поможет вам определить, насколько хорошо вы усвоили весь модуль. Вопросы теста охватывают все темы модуля. Пройдя этот тест, вы сможете определить свой прогресс и понять, какие темы вам необходимо повторить. Удачи!'
    },
  },
  your_result: 'Ваш результат',
  result: {
    low: 'Вы показали низкий результат. Вам необходимо повторить некоторые темы.',
    middle: 'Вы показали средний результат. Вам необходимо повторить некоторые темы.',
    high: 'Замечательно! Вы показали высокий результат.',
  }
}

export default locale
