import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  use_solution: 'Кеңесті қолдану ({{left}})',
  solution_order: 'Кеңес #{{order}}',
  check_answer: 'Жауапты тексеру',
  next_question: 'Келесі сұрақ',
  quit: 'Шығу',
  prev: 'Артқа',
  next: 'Келесі',
  finish: 'Тестті аяқтау',
  pagination: '{{current}} / {{total}}',
  review_errors: 'Қатемен жұмыс',
  not_answered: 'Жауап берілмеді',
  change_answer: 'Жауапты өзгерту',
  select_answer: 'Жауапты таңдаңыз',
  save_answer: 'Жауапты сақтау',
  support: {
    btn: 'Қате таптыңыз ба?',
    title: 'Қате түрін таңдаңыз',
    description_placeholder: 'Егер сіздің қатеңіз тізімде жоқ болса, қатеңізді осы жерге толығырақ жазып беруіңізді сұраймыз...',
    cancel: 'Болдырмау',
    submit: 'Жіберу',
    success_modal: {
      title: 'Көмегіңізге рахмет!',
      subtitle: 'Қателерімізді таба отырып, сіз біздің дамуымызға көп көмектесесіз!',
      close: 'Жабу',
    },
  },
}

export default locale
