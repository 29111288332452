import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  deadline: 'Дедлайн: {{date}}',
  finished: 'Завершен',
  count: 'Кол-во заданий: {{count}}',
  not_started_modal: {
    title: 'Домашнее задание пока недоступно',
    message: 'Начало домашнего задания в {{date}}',
  },
}

export default locale
