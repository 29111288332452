import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  main: {
    title: 'Тренажер',
    banner: {
      title: 'Тесттерді тапсырып<br>біліміңізді бекітіңіз',
      btn: 'Толығырақ',
    },
    info_modal: {
      title: '«Тренажер»<br>деген не?',
      message: 'Тренажер - белгілі бір пәндер мен тақырыптар бойынша біліміңізді шыңдай алатын орын. 5 негізгі пән бойынша кез келген тақырып бойынша тест тапсырмаларын орындап, өтілген материалды бекітіңіз. Іске сәт!',
    },
    results: {
      title: 'Апта нәтижесі',
      empty: 'Деректер жоқ',
      today_correct_answers: 'Бүгін: {{count}} дұрыс жауап',
    },
    subjects: {
      title: 'Сабақтар тізімі',
    },
  },
  subthemes: {
    select_subtheme: 'Тақырыпты таңдаңыз',
    empty_theme_list: 'Бұл пән бойынша әлі сұрақтар жоқ. Біздің ұжым олармен қазір жұмыс істеуде.',
    progress: 'Прогресс: {{progress}}%',
    test_finished: 'Тест аяқталды!',
    finished: 'Орындалды',
    restart: 'Қайталау',
    restart_modal: {
      title: 'Тақырыпты қайтадан бастағыңыз келе ме?',
      confirm: 'Қайтадан бастау',
      cancel: 'Болдырмау',
    },
  },
  subthemes_filter: {
    title: 'Тақырып таңдау',
  },
  star_gain_success: {
    title: 'Құттықтаймыз!',
    message: 'Сіз 20 сұраққа дұрыс жауап беріп, бүгін 1 жұлдызша алдыңыз',
  },
}

export default locale
