import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {map, Observable, Subject} from 'rxjs'
import moment, {Moment} from 'moment'

import {env} from '@ui/env'
import {CONTACTS_PERMISSION, CONTACTS_REQUEST, CONTACTS_UPDATE, HTTP_PARAM_SKIP_ERROR_HANDLE} from '@ui/constants/constants'

@Injectable({
  providedIn: 'root',
})
export class RnContactsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  init(): void {
    window[CONTACTS_PERMISSION] = new Subject<any>()
    window[CONTACTS_REQUEST] = new Subject<any>()
    window[CONTACTS_UPDATE] = new Subject<any>()
  }

  getLastSharedDate(): Observable<Moment> {
    const params = {[HTTP_PARAM_SKIP_ERROR_HANDLE]: true}
    return this.http.get<any>(`${env.apiHost}/api/v1/contacts/user_shares/`, {params})
      .pipe(
        map(info => {
          return info.updated_at ? moment(info.updated_at) : null
        }),
      )
  }

  get permission$(): Observable<boolean> {
    return window[CONTACTS_PERMISSION].asObservable()
  }

  get request$(): Observable<string> {
    return window[CONTACTS_REQUEST].asObservable()
  }

  get update$(): Observable<string> {
    return window[CONTACTS_UPDATE].asObservable()
  }
}
