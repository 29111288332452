import {LocaleDataModel} from '@ui/schemes/app/locale.model'
import lesson_card from './lesson-card/ru'
import homework_card from './homework-card/ru'
import test from './test/ru'

const locale: LocaleDataModel = {
  // shared components
  lesson_card,
  homework_card,
  test,
  // often used
  all: 'Все',
  lessons: 'Уроки',
  lectures: 'Лекции',
  homeworks: 'Домашние задания',
  lesson_count: 'Уроки: {{count}}',
  lesson_order: 'Урок №{{order}}',
  look: 'Смотреть',
  apply: 'Применить',
  reset: 'Сбросить',
  save: 'Сохранить',
  yesterday: 'Вчера',
  today: 'Сегодня',
  tomorrow: 'Завтра',
  days: '{{d}} дней',
  hours: '{{h}} час',
  minutes: '{{m}} мин',
  seconds: '{{s}} сек',
  start: 'Начать',
  continue: 'Продолжить',
  finish: 'Завершить',
  finished: 'Завершено',
  loading: 'Загрузка...',
  empty_list: 'Список пуст',
  edu_type_change_btn: 'Изменить режим обучения',
  edu_type_picker: {
    title: 'Выберите режим обучения',
    message: 'Чтобы продолжить, выберите режим обучения. Вы можете изменить режим обучения позже в любое время.',
    types: [
      'Запланированный',
      'Самостоятельный',
    ],
  },
  you: 'Вы',
  find: 'Найти',
  warning: 'Внимание',
  server_error: 'Ошибка сервера',
  not_found: 'Не найдено',
  server_error_try_later: 'Ошибка сервера. Пожалуйста, повторите попытку позже.',
  cancel: 'Отмена',
  navigate: 'Перейти',
}

export default locale
