import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Жеңілдік жүйесі',
  finished: 'Аяқталды',
  start_at: 'Басталу күні:',
  end_at: 'Аяқталу күні:',
  gained: 'Жинақталған:',
  stars: '{{count}}/{{total}} жұлдызша',
  discount_range: '{{goal}} жұлдыз {{percent}}%',
  price: 'Бағасы: {{price}}₸',
  discount: 'Жеңілдік: {{discount}}%',
  pay_btn: 'Төлем: {{price}}₸',
  pay_enabled_at: 'Төлем {{date}} күні ашық болады',
  discount_end_at: 'Жеңілдік {{date}} күніне дейін жарамды',
}

export default locale
