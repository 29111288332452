import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  list: 'Предметы',
  select_subject: 'Выбор предмета',
  lesson_total_count: 'Количество уроков: {{count}}',
  lesson_finished_count: 'Завершенные: {{count}}',
  expire: 'Предмет не оплачен',
}

export default locale
