import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  description: 'Описание',
  materials: 'Материалы',
  read_all: 'Читать все',
  broadcast_tab: 'Прямой эфир',
  extras_tab: 'Доп. материалы',
  broadcast_record_unavailable: 'Запись эфира еще не доступна',
  lock: {
    header: 'Предмет не оплачен',
    content: `Обучение за следующий период <span class="nowrap color-red">не оплачено.</span> Обратитесь по номеру <b class="nowrap">+7 747 650 37 22</b> для уточнения деталей.`,
  },
  star: {
    tutorial: {
      title: 'Чтобы получить звездочку необходимо просмотреть все видеоматериалы',
      message: 'Посмотрите все лекции и поизучайте материалы, они пригодятся вам в выполнении домашних задач!',
    },
    gain_success: {
      title: 'Задание выполнено!',
      message: 'Поздравляем, вы получили 1 звезду за просмотр лекции',
    },
  },
}

export default locale
