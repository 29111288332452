import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: '№{{order}} Сабақ',
  hint: 'Тапсырмаларды орындап, жұлдызша алыңыз',
  missed: 'Алынған жоқ',
  lecture: {
    title: 'Лекция',
    available: 'Лекцияны қараңыз',
    gained: 'Лекция қаралды',
  },
  homework: {
    title: 'Үй тапсырмасы',
    available: 'Үй тапсырмасын орындаңыз',
    score: '{{percent}}%-ы орындалды',
  },
  trainer: {
    title: 'Тренажерка',
    available: '{{goal}} сұраққа дұрыс жауап беріңіз',
    score: '{{count}}/{{goal}} сұраққа дұрыс жауап берілді',
    gained: 'Тренажерка орындалды',
  },
}

export default locale
