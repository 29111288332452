import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  comments: 'Комментарий',
  send_comment: 'Отправить',
  write_comment: 'Введите текст комментария',
  plug: 'Урок уже начался! Перейдите по ссылке, которую вам прислали кураторы в группах.',
  navigate: 'Перейти к трансляции',
}

export default locale
