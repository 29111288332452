import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  deadline: 'Аяқталуы: {{date}}',
  finished: 'Аяқталды',
  count: 'Тапсырма саны: {{count}}',
  not_started_modal: {
    title: 'Үй тапсырмасы әлі ашылмады',
    message: 'Үй тапсырмасының басталуы: {{date}}',
  },
}

export default locale
