import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Урок №{{order}}',
  hint: 'Выполните задачи и получите сегодняшние звездочки',
  missed: 'Пропущено',
  lecture: {
    title: 'Лекция',
    available: 'Поизучайте лекции',
    gained: 'Вы посмотрели лекцию',
  },
  homework: {
    title: 'Домашнее задание',
    available: 'Завершите задачи',
    score: 'Выполнено на {{percent}}%',
  },
  trainer: {
    title: 'Тренажерка',
    available: 'Решите {{goal}} задач',
    score: 'Вы решили {{count}}/{{goal}} задач',
    gained: 'Тренажерка выполнена',
  },
}

export default locale
