import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  list: 'Пәндер',
  select_subject: 'Пән таңдау',
  lesson_total_count: 'Сабақтар саны: {{count}}',
  lesson_finished_count: 'Аяқталғандар: {{count}}',
  expire: 'Пән төленбеген',
}

export default locale
