import {LocaleDataModel} from '@ui/schemes/app/locale.model'

const locale: LocaleDataModel = {
  title: 'Дайындық',
  lessons_empty: 'Бүгін сабақтар жоқ',
  homeworks_empty: 'Үй тапсырмалары жоқ',
  sections: {
    title: 'Дайындық бөлімдері',
    list: [
      {
        title: 'Менің сабақтарым',
        description: 'Онлайн курс материалдары',
        expire: 'Төленбеген пән бар',
      },
      {
        title: 'Тренажер',
        description: 'Тесттерді орындау арқылы ҰБТ-ға дайындал',
      },
      {
        title: 'ҰБТ 2022 сұрақтары',
        description: 'ҰБТ сұрақтарына жауап бер',
      },
      {
        title: 'Апта сайынғы сынақ',
        description: 'Басқа оқушылармен жарысыңыз',
      },
    ],
  },
  trial_test: {
    title: 'Онлайн сынақ тесті',
    start: 'Басталуы',
    end: 'Аяқталуы',
    take_test: 'Тестті тапсыру',
    my_result: 'Менің нәтижем',
  },
  add: 'Қосу',
  your_result: 'Сіздің нәтижеңіз',
  trainer: '{{threshold}} сұраққа дұрыс жауап беріп, жұлдызша алыңыз',
  connect_friend: 'Досыңды қосу',
  waiting_answer: 'Жауап күтудеміз',
  accept_request: 'Қабылдау',
  friend_req: {
    search: {
      title: 'Досыңды табу',
      subtitle: 'Buddy-ды қосып, үлгерімі мен белсенділігін қадалағау арқылы бір-біріңізді ынталандырыңыз. Buddy-мен бірге қызықты әрі тиімді дайындалыңыз!',
      label: 'Телефон нөмірін енгізіңіз',
    },
    found: {
      send_request: 'Өтініш жіберу',
    },
    sent: {
      title: 'Өтінішіңіз жіберілді',
      message: '<span class="text-primary">{{name}}</span> пайдаланушысына өтінішіңіз жіберілді. Жауап күтіңіз.',
    },
    accepted: {
      title: 'Өтінішіңіз қабылданды',
      message: '<span class="text-primary">{{name}}</span> өтінішіңізді қабылдады. Оқуларыңызға сәттілік!',
    },
    declined: {
      title: 'Өтінішіңіз қабылданбады',
      message: '<span class="text-primary">{{name}}</span> өтінішіңізді қабылдамады.',
    },
    disconnect: {
      title: 'Әрекетті растау',
      message: '<span class="text-primary">{{name}}</span> пайдаланушысымен байланысты тоқтатқыңыз келе ме?',
      confirm: 'Иә, тоқтату',
    },
  },
  lessons: 'Сабақтар',
}

export default locale
